/* eslint-disable no-undef */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from "react";
import Img from "gatsby-image";
import styles from "./hero.module.css";

const HeroBlock = ({ heroComponent }) => {
  return (
    <div className={styles.grid}>
      <div className={styles.grid__item}>
        <h1 className="small__title">{heroComponent.title}</h1>
        <h2 className={styles.hero__title}>{heroComponent.subTitle}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: heroComponent.description.childMarkdownRemark.html,
          }}
        />
        <div className={styles.hero__cta}>
          {heroComponent.callToAction.map((item) => {
            if (item.class === "button__ios-store") {
              return (
                <a key={item.id} href={item.url} title={item.title}>
                  <img alt={item.title} src="images/appstore.png" width="140" />
                </a>
              );
            }
            if (item.class === "button__google-play") {
              return (
                <a key={item.id} href={item.url} title={item.title}>
                  <img
                    alt={item.title}
                    src="images/playstore.png"
                    width="140"
                  />
                </a>
              );
            }
            return (
              <a
                key={item.id}
                href={item.url}
                title={item.title}
                className={item.class}
              >
                {item.title}
              </a>
            );
          })}
        </div>
      </div>
      <div className={styles.grid__item}>
        <Img
          className={styles.heroImage}
          fluid={heroComponent.image.fluid}
          alt={heroComponent.image.title}
        />
      </div>
    </div>
  );
};

export default HeroBlock;
