/* eslint-disable react/prop-types */
import React from "react";
import { Helmet } from "react-helmet";

const PageHead = ({ data, location }) => {
  const { pageTitle, metaDescription, metaKeywords, robots } = data;

  const name = pageTitle || "Sukar Diabetes App";
  const description =
    metaDescription ||
    "Diabetes and Glucose monitoring app that helps control your blood sugar levels";
  const keywords = metaKeywords || "sukar, glucose, diabetes, blood sugar";
  const seoRobots = robots || "index, follow";
  const url = location ? location.href : "https://sukar.app";

  return (
    <Helmet>
      <title>{name}</title>
      <meta property="og:title" content={name} />
      <meta name="twitter:title" content={name} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="robots" content={seoRobots} />
      <meta itemProp="name" content="Sukar - Diabetes & Glucose App" />
      <meta name="url" content={url} />
      <meta itemProp="url" content={url} />
      <html lang="en" />
    </Helmet>
  );
};

export default PageHead;
