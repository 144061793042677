/* eslint-disable react/style-prop-object */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

const ContentBlock = ({ title, content }) => {
  return (
    <div className="content__wrapper">
      <h1>{title}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: content.childMarkdownRemark.html,
        }}
      />
    </div>
  );
};

ContentBlock.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ContentBlock;
