/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Layout from "../../components/layout/layout";
import PageHead from "../../components/PageHead";
import ContentBlock from "../../components/content";
import HeroBlock from "../../components/hero";
import UspBlock from "../../components/usp";

const cleanBlockName = (block) => {
  return block.replace("Contentful", "");
};

class ContentPageTemplate extends React.PureComponent {
  render() {
    const page = get(this.props, "data.contentfulContentPage");

    return (
      <Layout location={this.props.location}>
        <div style={{ background: "#fff" }}>
          <PageHead data={page} location={this.props.location} />
          {page.blocks &&
            page.blocks.map((block) => {
              const type = cleanBlockName(block.__typename);
              switch (type) {
                case "ContentComponent":
                  return <ContentBlock key={block.id} {...block} />;
                case "HeroBlock":
                  return <HeroBlock key={block.id} {...block} />;
                case "UspBlock":
                  return <UspBlock key={block.id} {...block} />;
                default:
                  return false;
              }
            })}
        </div>
      </Layout>
    );
  }
}

export default ContentPageTemplate;

export const pageQuery = graphql`
  query ContentPageBySlug($slug: String!) {
    contentfulContentPage(slug: { eq: $slug }) {
      pageTitle
      metaDescription
      metaKeywords
      robots
      title
      blocks {
        __typename
        ... on Node {
          ...contentComponent
          ...heroBlock
          ...uspBlock
        }
      }
    }
  }
`;
