/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from "react";
import Usp from "./usp";
import styles from "./usp.module.css";

const UspBlock = ({ uspComponent }) => {
  return (
    <div className={styles.grid}>
      {uspComponent.map((item) => {
        return <Usp key={item.id} {...item} />;
      })}
    </div>
  );
};

export default UspBlock;
