/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from "react";
import styles from "./usp.module.css";

const Usp = (props) => {
  return (
    <div className={styles.grid__item}>
      <img
        alt={props.thumbnail.title}
        src={props.thumbnail.file.url}
        width="80"
        height="80"
      />
      <h3>{props.title}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: props.description.childMarkdownRemark.html,
        }}
      />
    </div>
  );
};

export default Usp;
